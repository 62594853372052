@tailwind base;
*::-webkit-scrollbar {
    width: 5px;
}

*::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
    border-radius: 0px;
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(0, 0, 0);
    border: 1px thin gray;
}
@tailwind components;
@tailwind utilities;



.customHeight {
    height: 100vh;
}
.customHeightAdminContent {
    height: 90vh;
}
.customHeightNew {
    height: 100vh;
}

.customFont {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.customWidth {
    width: 43vh;
}
.text-custom {
    font-size: 12px;
}
.customHeightSidebar {
    height: 100vh;
}
.quickInfoButtonClass {
    @apply text-base w-fit font-medium leading-none text-gray-600 py-3 md:px-2 px-1 bg-[#faedcd] rounded hover:bg-[#faedcd] focus:outline-none;
}
.detailIcon {
    @apply text-base w-fit font-medium leading-none text-black p-1 rounded hover:bg-gray-200 focus:outline-none;
}

@layer components {
    /* Hide scrollbar for Firefox */
    /* Note: -moz- properties are necessary for Firefox */
    body {
        scrollbar-width: none; /* Firefox */
    }

    /* Hide scrollbar for other browsers */
    body::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }

    /* Style your content area to occupy the entire space */
    /* Example styles, adjust as needed */
    .content {
        height: 100vh; /* Adjust the height as needed */
        overflow-y: scroll; /* Enable vertical scrolling */
        padding-right: 17px; /* Compensate for the scrollbar space */
    }
}
.customHeightRegister {
    height: 100vh;
}
.customHeightRegister {
    height: 70vh;
}
.customHeightStudentsList {
    max-height: 85vh;
}

.loadingSpinnerContainer {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 5000;
    display: flex;
    justify-content: center;
    align-items: center;
}
.loadingSpinner {
    width: 64px;
    height: 64px;
    border: 8px solid;
    border-color: rgb(245, 136, 12) transparent rgb(245, 136, 12) transparent;
    border-radius: 50%;
    animation: spin 1.2s linear infinite;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.customPopupHeight {
    height: 80vh;
}
/* Add these styles to your existing CSS file or component */
.table-container {
    overflow-x: auto;
}

.shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid rgb(54, 97, 197);
    margin-top: 20px;
}

.inner-table {
    width: 100%;
    border-collapse: collapse;
}

.inner-table th,
.inner-table td {
    padding: 8px;
    text-align: center;
    color: black;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-weight: 600;
}

.inner-table th {
    background-color: rgb(54, 75, 184);
    font-weight: bold;
    color: white;
}

.border-row {
    border-top: 1px solid #e5e7eb;
}

.border-cell {
    border-right: 1px solid #e5e7eb;
}

/* Responsive styles */
@media screen and (max-width: 420px) {
    .custom-table {
        width: auto;
        overflow: auto;
        display: block;
    }

    .inner-table th,
    .inner-table td {
        max-width: 80px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }
    .customWidth {
        width: 100%;
    }
}
@media screen and (max-width: 431px) {
    .custom-table {
        width: auto;
        overflow: auto;
        display: block;
    }

    .customWidth {
        width: 100%;
    }

    .inner-table th,
    .inner-table td {
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
    }
}

@media screen and (min-width: 1024px) {
    /* Your styles for the specified range of widths */
    .customWidth {
        width: 43vh;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    /* Your styles for the specified range of widths */
    .customWidth {
        width: 33vh;
    }
}
