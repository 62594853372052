@tailwind base;
@tailwind components;
@tailwind utilities;

.textInput {
    @apply py-1.5 px-4 text-sm bg-white text-gray-900 border-0 rounded-md border-b-2 border-gray-300  shadow-md placeholder:text-gray-500;
}

.fileInput {
    @apply block py-1.5 w-full text-sm text-gray-400 min-w-20 px-4 rounded-md bg-white border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:border-blue-500 shadow-md;
}
option {
    @apply text-gray-500 hover:text-blue-400;
}
.navbar {
    z-index: 10000;
}

@media screen and (max-width: 1100px) {
    .sidebar {
        z-index: 10000000;
    }
}

.containerBoxShadow {
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    width: 95%;
    margin-bottom: 40px;
    /* background-color: white */
    @apply bg-slate-100
}

.containerBlock {
    @apply container mx-auto  p-6 bg-sky-50 rounded-lg shadow-lg w-full;
}

.buttonClass {
    @apply text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2;
}

.formBackground {
    @apply border border-2 border-gray-50   shadow-md rounded-md bg-violet-300;
}



.tableHead {
    @apply text-xs text-white uppercase bg-black
}

.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background-color: rgba(156, 163, 175, 0.6); /* Gray color */
    border-radius: 8px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgba(107, 114, 128, 0.8); /* Darker gray */
  }

  
/* adjusting datatable colvis height */
.dt-button-collection {
    height: 300px;
    overflow-y: scroll !important;
  }
  .dt-scroll {
    overflow: auto !important;
  }
  .extraCSS thead tr th {
    background-color: black !important; /* Set the background color to black */
    color: white !important; /* Set the text color to white */
    text-transform: uppercase;
    font-weight: 600;
    font-size: small;
  }
  .dt-button {
    @apply !text-white !bg-gradient-to-r from-cyan-500 to-blue-500 hover:!bg-gradient-to-bl focus:ring-4 focus:outline-none !ms-2 focus:ring-cyan-300  font-medium rounded-lg text-sm px-2 py-2;
  }
  
  .dt-input {
    @apply block py-1.5 px-4 text-sm !h-fit !my-auto !font-semibold !bg-white text-gray-900 !border-0 !rounded-md !border-b-2 !border-gray-300 !appearance-none focus:!outline-none focus:!border-blue-500 placeholder:!font-normal !shadow-md placeholder:!text-gray-500;
  }
  
  .dt-search {
    @apply !flex !flex-row !justify-start;
  }
  .dt-search label {
    @apply !basis-[20%] !text-sm !w-full !text-start !font-semibold !uppercase !my-auto;
  }

  .table-bg-employeeClass {
    @apply bg-gradient-to-r from-emerald-100 to-teal-300;
  }
  .accordian-title {
    @apply text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-1.5 mr-4;
  }
  .sub-headings {
    @apply text-center w-fit bg-[#6EE6B6] text-gray-800 rounded px-4 uppercase text-xl;
  }

  .tabButton {
    @apply bg-gradient-to-r from-cyan-500 to-blue-500 text-white;
  }
  .sub-headings {
    @apply text-center w-fit bg-[#6EE6B6] text-gray-800 rounded px-4 uppercase text-xl;
  }
  .closeButtonClass {
    @apply text-white bg-gradient-to-r from-pink-500 to-red-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-3 py-2;
  }
  